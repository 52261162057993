/* eslint-disable no-unused-vars */
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import QRCode from "react-qr-code";
import { useStores } from 'src/stores';
import AppStoreLogo from '@src/assets/svg/appstore_logo.svg';
import GooglePlayLogo from '@src/assets/svg/googleplay_logo.svg';
import Code from '@src/assets/svg/Code.svg';

import styles from './mobileSign.module.scss';

const MobileSignPage = observer(() => {
  const [searchParams, setSearchParams] = useSearchParams();
  const stores = useStores();
  // eslint-disable-next-line no-unused-vars
  const _ = stores.language;
  const { isAndroid, isMobile } = stores.browserInfo;
  const data = searchParams.get('data');

  let url;
  if (isMobile) {
    if (isAndroid) {
      url = `intent://moneta.today/mobile/sign?data=${data}#Intent;scheme=https;package=moneta.today;end`;
    } else {
      url = new URL("/mobile/sign", "https://app.moneta.today");
      url.searchParams.set('data', data);
    }
  } else {
    url = new URL("/mobile/sign", "https://moneta.today");
    url.searchParams.set('data', data);
  }

  const link = url.toString();

  return (
    <div className={styles.MainContainer} style={{ backgroundImage: `url(${Code})` }}>
      <div className={styles.MainInnerContainer}>
        <p>{stores.translate('mobile_sign_download_text')}</p>

        <div className={styles.QRContainer}>
          <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%" }}
            value={link}
            viewBox="0 0 256 256"
            bgColor="#3f3e39"
            fgColor="white"
          />
          <a
            href={link}
            target="_blank"
            rel="noreferrer"
            className={styles.Link}
          >
            {stores.translate('go_to_link')}
          </a>
        </div>

        <div className={styles.AppLinks}>
          <a
            href="https://apps.apple.com/ru/app/moneta-today/id1605931536"
            className={styles.AppStoreLogoLink}
          >
            <img src={AppStoreLogo} alt="AppStoreLogo" loading="lazy" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=moneta.today"
            className={styles.GooglePlayLogoLink}
          >
            <img src={GooglePlayLogo} alt="GooglePlayLogo" loading="lazy" />
          </a>
        </div>
      </div>
    </div>
  );
});


export default MobileSignPage;
